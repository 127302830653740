jQuery(function ($) {
  var $body = $('body');
  var $window = $(window);
  openmenu();

  //* OPEN MENU
  function openmenu() {
    $body.on('click', '.burger', function () {
      $body.toggleClass('menu-shown');
    });
  }

  //* Fade in body
  function fadeIn() {
    $body.addClass('loaded');
    $('.nav li').on('click', function () {
      $body.removeClass('menu-shown');
    });
  }

  //* ELEMENTS VIEW DETECTION
  function isScrolledIntoView(elem) {
    var $docViewTop = $(window).scrollTop();
    var $docViewBottom = $docViewTop + $(window).height();
    var elemTop = $(elem).offset().top;
    return elemTop <= $docViewBottom;
  }

  //* ACCORDIONS
  if ($('.accordion').length) {
    if (viewport().width < 1024) {
      $('.acc-toggle').on('click', function () {
        if (!$(this).hasClass('active')) {
          $('.acc-toggle').removeClass('active');
          $('.acc-body').slideUp();
          $(this).addClass('active');
          $(this).next('.acc-body').slideDown();
        } else {
          $('.acc-toggle').removeClass('active');
          $('.acc-body').slideUp();
        }
      });
    } else {
      $('.acc-body').show();
    }
  }

  //* LIGHTBOX
  lightbox.option({
    disableScrolling: true
  });

  //* VIEWPORT
  function viewport() {
    var e = window,
      a = 'inner';
    if (!('innerWidth' in window)) {
      a = 'client';
      e = document.documentElement || document.body;
    }
    return {
      width: e[a + 'Width'],
      height: e[a + 'Height']
    };
  }

  //* MESSAGE LOI 25
  if ($('#message').length) {
    if (!localStorage.getItem('messageShown')) {
      document.getElementById('message').style.display = 'block';
    }
    $('#message .close').on('click', function () {
      $('#message').fadeOut();
      localStorage.setItem('messageShown', 'true');
    });
  }
  $window.on('scroll', function () {
    if ($(this).scrollTop() > 500) {
      $body.addClass('scroll');
    } else {
      $body.removeClass('scroll');
    }
    $('.inSight').each(function () {
      var $this = $(this);
      if (isScrolledIntoView(this) === true) {
        $this.addClass('alive');
      } else {
        $this.removeClass('alive');
      }
    });
  });
  $window.trigger('resize');
  $window.trigger('scroll');
  setTimeout(function () {
    fadeIn();
  }, 300);
});